/* list.css */
@import url('https://fonts.googleapis.com/css2?family=BIZ+UDPGothic&display=optional');

.scoring-btn {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  /* font-family: "BIZ UDPGothic", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: min(2.8vw,16px);
  letter-spacing: .02em;
  color: #fff;
  background-image: linear-gradient(0deg, #fff, #d700ff 30%, #3200ff 70% 90%);
  display: flex;
  justify-content: center;
  align-items: center; 
}
.scoring-btn button {
  background-color: rgba(0, 0, 0, 0);
}

  
  /* 縦横比が115/100より大きい場合のスタイル */
  @media screen and (min-aspect-ratio: 115/100) {
    .ScoreModalButton {
      bottom: 15vh; /* 例えば、ボタンの位置を下げる */
      color: black;
      top: 30%;
    }
  
    .score-number-blend {
      top: calc(50% + 100px); /* スコア表示位置を下げる */
    }
  }
  